import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { address } from './../../Footer/entity'
import ContactsMap from './../Map'
import { useScreenSize } from '../../../hooks'
import { MobileSlider } from './MobileSlider'
import ArrowBg from './img/arrow-bg.inline.svg'
import '../../../styles/variables/module.less'
import * as styles from './offices.module.less'

const Arrow = styled.div`
  position: absolute;
  top: ${({ position }) => position + 'px' || 0};
  z-index: -1;
  width: 190px;
  height: 65px;
  transition: top 0.3s ease 0s;

  @media screen and (min-width: @screen-l-min) {
    width: 210px;
    height: 65px;
  }

  @media screen and (min-width: @screen-xl-min) {
    width: 250px;
  }
`

const HEIGHT_ARROW = 65

const Offices = () => {
  const [currentCity, setCurrentCity] = useState('moscow')
  const [positionArrow, setPositionArrow] = useState(0)
  const isMobile = useScreenSize('mobile')
  const isSmallTablet = useScreenSize('smallTablet')
  const isMinTablet = useScreenSize('minTablet')

  const handleChangeCity = useCallback(
    (city, index) => {
      setCurrentCity(city)
      setPositionArrow(Number(index * HEIGHT_ARROW))
    },
    [setCurrentCity, setPositionArrow]
  )

  return (
    <div className={styles.officesWrapper}>
      <div className={styles.addressesContainer}>
        {isMobile || isSmallTablet ? (
          <MobileSlider setCurrentCity={setCurrentCity} />
        ) : (
          Object.keys(address).map((city, index) => (
            <div
              className={styles.address}
              key={city}
              onClick={() => handleChangeCity(city, index)}
            >
              <div className={styles.city}>{address[city].city}</div>
              <div className={styles.street}>{address[city].streets[0]}</div>
            </div>
          ))
        )}
        {isMinTablet && (
          <Arrow position={positionArrow}>
            <ArrowBg />
          </Arrow>
        )}
      </div>
      <div className={styles.mapContainer}>
        <ContactsMap {...address[currentCity]} />
      </div>
    </div>
  )
}

export default Offices
