import React, { useCallback, useMemo, useRef, useState } from 'react'
import Slider from 'react-slick'
import { address } from './../../Footer/entity'
import * as styles from './offices.module.less'
import cn from 'classnames'

export const MobileSlider = ({ setCurrentCity }) => {
  const ref = useRef()
  const [activeSlide, setActiveSlide] = useState(0)

  const afterChange = useCallback(
    (current) => {
      setCurrentCity(Object.keys(address)[current])
    },
    [setCurrentCity]
  )

  const onBeforeChange = useCallback(
    (current, next) => {
      setActiveSlide(next)
    },
    [activeSlide]
  )

  const settings = useMemo(
    () => ({
      slidesToShow: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      arrows: false,
      infinite: false,
      afterChange: afterChange,
      beforeChange: onBeforeChange,
    }),
    []
  )

  return (
    <Slider
      ref={ref}
      className={cn(styles.contactSlider, 'contactsSlider')}
      {...settings}
    >
      {Object.keys(address).map((city, index) => (
        <div
          className={cn(styles.address, {
            [styles.addressActive]: index === activeSlide,
            [styles.addressPrev]: index < activeSlide,
            [styles.addressNext]: index > activeSlide,
          })}
          key={city}
        >
          <div className={styles.city}>{address[city].city}</div>
          <div className={styles.street}>{address[city].streets[0]}</div>
        </div>
      ))}
    </Slider>
  )
}
