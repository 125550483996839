import React from 'react'
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import PlacemarkIcon from './placemark.svg'

const ContactsMap = ({ coordinates, streets }) => {
  return (
    <YMaps>
      <div style={{ borderRadius: '10px', overflow: 'hidden', height: '100%' }}>
        <Map
          state={{
            center: coordinates,
            zoom: 15,
            controls: ['zoomControl', 'fullscreenControl'],
          }}
          width={'100%'}
          height={'100%'}
          modules={['control.ZoomControl', 'control.FullscreenControl']}
        >
          <Placemark
            modules={['geoObject.addon.balloon']}
            geometry={coordinates}
            properties={{
              balloonContentBody: streets,
            }}
            options={{
              iconLayout: 'default#image',
              iconImageHref: PlacemarkIcon,
            }}
          />
        </Map>
      </div>
    </YMaps>
  )
}

export default ContactsMap
