// extracted by mini-css-extract-plugin
export var address = "offices-module--address--edd8c";
export var addressActive = "offices-module--addressActive--3268b";
export var addressNext = "offices-module--addressNext--cf886";
export var addressPrev = "offices-module--addressPrev--2a327";
export var addressesContainer = "offices-module--addressesContainer--d5f30";
export var city = "offices-module--city--ddb80";
export var contactSlider = "offices-module--contactSlider--dff9d";
export var mapContainer = "offices-module--mapContainer--3332b";
export var officesWrapper = "offices-module--officesWrapper--c1879";
export var street = "offices-module--street--a1b24";