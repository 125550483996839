import React from 'react'
import { BASIS_PHONE } from '../../constants'
import Layout from '../../components/Layout'
import Offices from '../../components/Contacts/Offices'
import * as styles from './contacts.module.less'

const Contacts = () => {
  return (
    <Layout>
      <h1>Контакты</h1>
      <div className={styles.content}>
        <div className={styles.contactsInfo}>
          <div>
            <div className={styles.title}>Горячая линия</div>
            <div className={styles.info}>
              <a href={`tel:${BASIS_PHONE.phoneHref}`}>
                {' '}
                {BASIS_PHONE.phoneNumber}{' '}
              </a>
              <a href="mailto:feedback@ipoteka.center">
                feedback@ipoteka.center
              </a>
            </div>
          </div>
          <div>
            <div className={styles.title}>Для прессы</div>
            <div className={styles.info}>
              <a href="mailto:pr@ipoteka.center">pr@ipoteka.center</a>
            </div>
          </div>
        </div>
        <div className={styles.contactsOffices}>
          <div className={styles.title}>Наши офисы</div>
          <Offices />
        </div>
      </div>
    </Layout>
  )
}

export default Contacts
